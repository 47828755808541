<template>
    <div class="common-form">
        <!-- 标题 -->
        <div class="common-form-layout">
            <global-page-back
            detailPageTitle="巡课详情"
            @handlerGlobalType="handlerGlobalType"
            >
            </global-page-back>
        </div>
        <div class="class-patrol-temp-wrapper">
            <div v-if="!errorShow">
                <loading :loadingShow="loadingShow"></loading>
                <div v-if="!loadingShow" class="content-wrapper">
                   <Title
                        :tabList="tabList"
                        :selectTabId="selectTabId"
                        :tourDetailsClone="tourDetailsClone"
                        :source="source"
                        @addTabCards="addTabCards"
                        @handlerTabChange="handlerTabChange"
                        @submitAll="submitAll"
                        @delCard="delCard"
                    />
                    <class-patrol-form
                        ref="ClassPatrolForm"
                        :ruleForm="ruleForm"
                        :rules="rules"
                        :gradeList="gradeList"
                        :classList="classList"
                        :courseList="courseList"
                        :classPeriodList="classPeriodList"
                        :supervisingTeachersList="supervisingTeachersList"
                        :supervisingTeachersList1="supervisingTeachersList1"
                        :formTabsList="formTabsList"
                        :formTabsPointer="formTabsPointer"
                        :evaluationForm="evaluationForm"
                        :tourDetailsClone="tourDetailsClone"
                        @changeFormTabs="changeFormTabs"
                        @handlerTourGrade="handlerTourGrade"
                        @handlerCourseIdChange="handlerCourseIdChange"
                        @handlerRestTimeIdChange="handlerRestTimeIdChange"
                        @handlerClassIdChange="handlerClassIdChange"
                        @handlerClassTimeChange="handlerClassTimeChange"
                        @handlerChangeDeep="handlerChangeDeep"
                        @handlerSupervisingTeacherChange="handlerSupervisingTeacherChange"
                    >
                    </class-patrol-form>
                </div>
            </div>
            <error v-else :errorShow="errorShow"></error>
        </div>
    </div>
</template>
<script>
import {
    // 业务组件
    FilterData,
    TableData,
    Pagination,
    // 功能组件
    Loading,
    Error,
    DialogWrapper,
    // 工具函数
    debounce
} from 'common-local';
import Title from './ClassPatrolTemp/Sub/Title.vue';
import ClassPatrolForm from './ClassPatrolTemp/ClassPatrolForm.vue';
import { mapState } from 'vuex';
import GlobalPageBack from '../Sub/GlobalPageBack/index';
import { TeachingResearchCourseScheduleTempModel } from '@/models/TeachingResearchCourseScheduleTemp.js';
export default {
    name: "ClassPatrolTemp",
    components: {
        Loading,
        Error,
        Title,
        ClassPatrolForm,
        GlobalPageBack
    },
    props: {
        tourDetails: Object,
        tourDetails_record: Object
    },
    data () {
        return {
            errorShow: false,
            loadingShow: false,

            /**
             * tab
             * selectTabId 选中tab 选项卡
             * */
             tabList: [],
             selectTabId: 0,
            /**
             * 表单相关
             * */
             ruleForm: {
                id: '',
                schoolId: '',
                productId: '',
                watchTitle: '',

                gradeId: '', // 年级
                classId: '', // 班级
                classTime: '', // 上课日期
                courseId: '', // 课程
                restTimeId: '', // 上课时间段
                supervisingTeacherId: '', // 督导教师

                highLights: '', // 亮点
                question: '', // 问题
                instructionManual: '', // 备注说明
                isResultSend: false, // 异常结果发送给教师
                isExcellentExcellent: false, // 优秀推荐
             },
             rules: {
                gradeId: [
                    { required: true, message: '请选择', trigger: 'change' },
                ],
                classId: [
                    { required: true, message: '请选择', trigger: 'change' },
                ],
                classTime: [
                    { required: true, message: '请选择', trigger: 'change' },
                ],
                courseId: [
                    { required: true, message: '请选择', trigger: 'change' },
                ],
                restTimeId: [
                    { required: true, message: '请选择', trigger: 'change' },
                ],
                supervisingTeacherId: [
                    { required: true, message: '请选择', trigger: 'change' },
                ],
                isResultSend: [
                    { required: true, message: '请选择', trigger: 'change' },
                ],
                excellentRecommendation: [
                    { required: true, message: '请选择', trigger: 'change' },
                ],
             },
            /**
             * 表单数据
             * gradeList 巡课年级下拉数据
             * classList 巡课班级下拉数据
             * courseList 课程下拉数据
             * classPeriodList 上课时间段下拉数据
             * supervisingTeachersList 督导教师下拉数据
             * supervisingTeachersList1 可编辑的时候需要
             *
             * */
             gradeList: [],
             classList: [],
             courseList: [],
             classPeriodList: [],
             supervisingTeachersOriginList: [],
             supervisingTeachersList: [],
             supervisingTeachersList1: [],
            /**
             * 表单辅助数据
             * tabsList 表单
             * formTabsPointer 表单指针
             * */
             formTabsList: [
                {
                    id: '0',
                    title: '教师评价'
                },
                {
                    id: '1',
                    title: '学生评价'
                },
                {
                    id: '2',
                    title: '亮点'
                },
                {
                    id: '3',
                    title: '问题'
                },
                {
                    id: '4',
                    title: '备注说明'
                }
             ],
            /**
             * formTabsPointer 评价当前选择
             * evaluationForm 评价渲染
             * evaluationFormAdd 评价渲染原始数据，用于添加
             * teacherStudentBehaviorLabelsTakeList 原始数据
             * */
             formTabsPointer: '0',
             evaluationForm: [
                {
                    id: '0',
                    idName: '教师评价',
                },
                {
                    id: '1',
                    idName: '学生评价'
                },
                {
                    id: '2',
                    idName: '亮点'
                },
                {
                    id: '3',
                    idName: '问题'
                },
                {
                    id: '4',
                    idName: '备注说明'
                }
             ],
             evaluationFormAdd: [],
             teacherStudentBehaviorLabelsTakeList: [],
            /**
             * 提交数据集合 reqObj
             */
             reqObj: {
                "type": '',
                "continueTourId": "",
                "list": [],
             },
             reqObjItem: {
                "schoolId": "",
                "productId": "",
                "watchTitle": "",
                "gradeId": "",
                "classId": "",
                "classTime": "",
                "courseId": "",
                "restTimeId": "",
                "supervisingTeacherId": "",
                "deptId": "",
                "highLights": "",
                "question": "",
                "isResultSend": "",
                "isExcellentExcellent": "",
                "instructionManual": "",
                "list": [
                    {
                        "type": "",
                        "behaviorLabelId": "",
                        "behaviorDataId": "",
                        "otherDesc": "",
                        "otherInformation": "",
                        "isAbnormal": "",
                        "abnormalResultFeedback": ""
                    }
                ],
                "id": ""
            },
            /**
             * 获取巡课标签数据 labelsTake
             * 排课星期 conditionList
             * */
             labelsTake: {
                schoolId: '',
                productId: ''
             },
             conditionList: {
                schoolId: '',
                productId: ''
             },
            /**
             * optionsAllList 下拉选项集合 数组
             *
             * */
             optionsAllList: [
                {
                    supervisingTeachersList: [],
                    supervisingTeachersList1: [],
                    classPeriodList: [],
                    courseList: []
                }
             ],
            /**
             * tourDetailsClone 源数据拷贝数据
             *
             * */
             tourDetailsClone: {},
             firstTime: true,
             source: ''
        }
    },
    created () {
        this.$eventDispatch('setGlobalPageType', 'form')
        this.firstTime = false;
        this.source = this.$route.query.source
        this.init('created')
        console.log('created');
    },
    activated () {
        if (this.firstTime) {
            this.init("activated");
        }
        console.log('activated');
    },
    deactivated() {
        this.firstTime = true;
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId
        }),
    },
    methods: {
        init() {
            this.initAssignment()
            this.initPageData()
        },
        // 返回方法
        handlerGlobalType(){
            this.$emit('classPatrolBack')
        },
        /**
         * @Description: 初始化赋值
         * @DoWhat: 初始化赋值
         * @UseScenarios: 初始化赋值
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-18 11:14:18
         */
         initAssignment () {
            console.log(this.tourDetails.type,this.tourDetails,'this.tourDetails 数据')
            this.tourDetailsClone = Object.assign(this.tourDetails, this.tourDetailsClone)

            this.loadingShow = true;
            // 行为标签数据
            this.labelsTake.schoolId = this.schoolId;
            this.labelsTake.productId = 'cloud_campus';
            // 排课星期请求赋值
            this.conditionList.schoolId = this.schoolId;
            this.conditionList.productId = 'cloud_campus';

            console.log(this.tourDetailsClone,'this.tourDetailsClone')
         },
        /**
         * @Description: 拼装表单数据 CourseSchedule 课程表/getEditForm 编辑
         * @DoWhat: 拼装表单数据
         * @UseScenarios: 拼装表单数据
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-18 16:33:28
         */
         async assemblyData (tourDetailsClone) {
            const selectT = tourDetailsClone.selectT;

            selectT.forEach((item) => {
                this.reqObj.list.push(this._.cloneDeep(this.reqObjItem))
            })

            // add 特殊处理
            if (tourDetailsClone.type == 'add') {
                this.reqObj.list.push(this._.cloneDeep(this.reqObjItem))
                this.reqObj.list.forEach((subItem) => {
                    subItem.schoolId = this.schoolId;
                    subItem.productId = 'cloud_campus';
                })
            }


            if (tourDetailsClone.type === 'CourseSchedule') {
                await this.getCourseScheduleForm (tourDetailsClone)
            }

            if (tourDetailsClone.type === 'edit') {
                this.getEditForm (tourDetailsClone)
            }

            if (tourDetailsClone.type === 'continueTour') {
                this.getEditForm (tourDetailsClone)
            }

            if (tourDetailsClone.type === 'detail') {
                this.getEditForm (tourDetailsClone)
            }

            if (tourDetailsClone.type === 'add') {
                this.getAddForm (tourDetailsClone)
            }

            this.loadingShow = false;
         },
         async getCourseScheduleForm (tourDetailsClone) {
            console.log('从课程表跳转', tourDetailsClone)
            // 拼装基本信息及设置项
            //supervisingTeacherId
            this.reqObj.list.forEach((item, index) => {
                console.log(this.tabList[index].teacherId,this.tabList, this.supervisingTeachersList,'tab-tab')
                item['schoolId'] = this.schoolId;
                item['productId'] = 'cloud_campus';
                item['watchTitle'] = this.tabList[index].title;
                item['gradeId'] = tourDetailsClone.scheduleGradeId;
                item['classId'] = tourDetailsClone.scheduleClassId;
                item['classTime'] = tourDetailsClone.scheduleTime;
                item['courseId'] = tourDetailsClone.id;
                item['restTimeId'] = tourDetailsClone._uuid;
                item['supervisingTeacherId'] = this.tabList[index].teacherId;
                item['deptId'] = this.tabList[index].id;
                item['id'] = tourDetailsClone.tourClassId;

                item['isResultSend'] = false;
                item['isExcellentExcellent'] = false;

                this.supervisingTeachersList[0].children.forEach((subItem) => {
                    if (item['supervisingTeacherId'] == subItem.id ) {
                        console.log(subItem,'item++++____')
                    }
                })

            })

            // 当前表单显示
            if (this.reqObj.list[0] && Object.keys(this.reqObj.list[0]).length > 0 ) {
                Object.keys(this.reqObj.list[0]).forEach((key) => {
                Object.keys(this.ruleForm).forEach((subKey) => {
                    if (key == subKey) {
                        this.ruleForm[subKey] = this.reqObj.list[0][key];
                    }
                })
            })
            }


            // 拼装评价

            let teacherEvaluation = [],
                studentEvaluation = [];

            if (this.teacherStudentBehaviorLabelsTakeList.length > 0) {
                teacherEvaluation = this.teacherStudentBehaviorLabelsTakeList.filter((item) => { return item.identityType == '1' });
                studentEvaluation = this.teacherStudentBehaviorLabelsTakeList.filter((item) => { return item.identityType == '2' });
            }

            console.log(teacherEvaluation, studentEvaluation, '课程表')
            this.evaluationForm.forEach((item) => {
                if (item.id == '0') {
                    item['identityType'] = '1';
                    if (teacherEvaluation.length > 0) {

                        item['labelList'] = teacherEvaluation[0].labelList;
                        item['labelList'].forEach((subItem) => {
                            if (subItem.dataList) {
                                subItem.dataList.push({
                                    dataName: '其他',
                                    id: '0'
                                })
                            } else {
                                subItem['dataList'] = [];
                                subItem['dataList'].push({
                                    dataName: '其他',
                                    id: '0'
                                })
                            }

                            if (subItem.labelType == '1') {
                                this.$set(subItem, 'value', '');

                                subItem.dataList.forEach((sIt) => {
                                    if (sIt.isDefault == '1') {
                                        this.$set(subItem, 'value', sIt.dataName);
                                    }
                                })

                            } else {
                                this.$set(subItem, 'value', []);
                                console.log(subItem,'subItem11')
                                subItem.dataList.forEach((sIt) => {
                                    if (sIt.isDefault == '1') {
                                        subItem.value.push(sIt.dataName)
                                    }
                                })
                            }


                            this.$set(subItem, 'otherDesc', '');
                            this.$set(subItem, 'otherInformation', '');
                            this.$set(subItem, 'isAbnormal', false);

                        })
                    }
                }

                if (item.id == '1') {
                    item['identityType'] = '2';
                    if (studentEvaluation.length > 0) {
                        item['labelList'] = studentEvaluation[0].labelList;
                        item['labelList'].forEach((subItem) => {
                            if (subItem.dataList) {
                                subItem.dataList.push({
                                    dataName: '其他',
                                    id: '0'
                                })
                            } else {
                                subItem['dataList'] = [];
                                subItem['dataList'].push({
                                    dataName: '其他',
                                    id: '0'
                                })
                            }

                            if (subItem.labelType == '1') {
                                this.$set(subItem, 'value', '');

                                subItem.dataList.forEach((sIt) => {
                                    if (sIt.isDefault == '1') {
                                        this.$set(subItem, 'value', sIt.dataName);
                                    }
                                })
                            } else {
                                this.$set(subItem, 'value', []);
                                subItem.dataList.forEach((sIt) => {
                                    if (sIt.isDefault == '1') {
                                        subItem.value.push(sIt.dataName)
                                    }
                                })
                            }
                            this.$set(subItem, 'otherDesc', '');
                            this.$set(subItem, 'otherInformation', '');
                            this.$set(subItem, 'isAbnormal', false);
                        })
                    }

                    if (item.id == '2' || item.id == '3' || item.id == '4') {
                        this.$set(item, 'otherInformation', '');
                    }
                }
            })

            this.reqObj.list.forEach((item, index) => {
                this.$set(item, '_list', this._.cloneDeep(this.evaluationForm))
            })


            await this.getObtainSupervisionDepartmentsBatches(this.reqObj.list)
            console.log(this.reqObj.list,'this.reqObj.list')

         },
         /**
          * @Description: 批量获取督导部门
          * @DoWhat: 批量获取督导部门
          * @UseScenarios: 批量获取督导部门
          * @Attention: 无
          * @Author: 武东超
          * @Date: 2022-11-24 10:22:10
          * @param {*} tourDetailsClone
          */
          async getObtainSupervisionDepartmentsBatches (list) {
            for(let i = 0; i < list.length; i++) {
                const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
                await teachingResearchCourseScheduleTempModel.getTeaOrganByTeacherId({'schoolId': this.schoolId, 'teacherId': list[i].supervisingTeacherId }).then((res) => {
                    if (res.data.code == '200') {
                        list[i].deptId = res.data.data[0].organId;
                    }

                })
            }
          },
         getEditForm (tourDetailsClone) {
            console.log(this.reqObj, tourDetailsClone, 'edit')
            this.reqObj.list.forEach((item, index) => {
                tourDetailsClone.list.forEach((subItem, subIndex) => {
                    if (index === subIndex) {
                        item['id'] =  subItem.tourClassId || subItem.id;
                        item['schoolId'] = subItem.schoolId;
                        item['productId'] = subItem.productId;
                        item['watchTitle'] = this.tabList[index].title;
                        item['gradeId'] = subItem.gradeId;
                        item['classId'] = subItem.classId;
                        item['classTime'] = subItem.classTime;
                        item['courseId'] = subItem.courseId
                        item['restTimeId'] = subItem.restTimeId;
                        item['supervisingTeacherId'] = subItem.supervisingTeacherId;
                        item['deptId'] = subItem.deptId;

                        item['isResultSend'] = subItem.isResultSend == '0' ? false : true;
                        item['isExcellentExcellent'] = subItem.isExcellentExcellent == '0' ? false : true;

                    }
                })
            })

            // 当前表单显示
            if (this.reqObj.list[0] && Object.keys(this.reqObj.list[0]).length > 0) {
                Object.keys(this.reqObj.list[0]).forEach((key) => {
                Object.keys(this.ruleForm).forEach((subKey) => {
                    if (key == subKey) {
                        this.ruleForm[subKey] = this.reqObj.list[0][key];
                    }
                })
            })
            }


            let teacherEvaluation = [],
                studentEvaluation = [];

            if (this.teacherStudentBehaviorLabelsTakeList.length > 0) {
                teacherEvaluation = this.teacherStudentBehaviorLabelsTakeList.filter((item) => { return item.identityType == '1' });
                studentEvaluation = this.teacherStudentBehaviorLabelsTakeList.filter((item) => { return item.identityType == '2' });
            }

            this.evaluationForm.forEach((item) => {
                if (item.id == '0') {
                    item['identityType'] = '1';
                    if (teacherEvaluation.length > 0) {
                    item['labelList'] = teacherEvaluation[0].labelList;
                    item['labelList'].forEach((subItem) => {
                        if (subItem.dataList) {
                            subItem.dataList.push({
                                dataName: '其他',
                                id: '0'
                            })
                        } else {
                            subItem['dataList'] = [];
                            subItem['dataList'].push({
                                dataName: '其他',
                                id: '0'
                            })
                        }

                        if (subItem.labelType == '1') {
                            this.$set(subItem, 'value', '');
                        } else {
                            this.$set(subItem, 'value', []);
                        }

                        this.$set(subItem, 'otherDesc', '');
                        this.$set(subItem, 'otherInformation', '');
                        this.$set(subItem, 'isAbnormal', false);

                        })
                    }
                }

                if (item.id == '1') {
                    item['identityType'] = '2';
                    if (studentEvaluation.length > 0) {
                        item['labelList'] = studentEvaluation[0].labelList;
                        item['labelList'].forEach((subItem) => {
                            if (subItem.dataList) {
                                subItem.dataList.push({
                                    dataName: '其他',
                                    id: '0'
                                })
                            } else {
                                subItem['dataList'] = [];
                                    subItem['dataList'].push({
                                        dataName: '其他',
                                        id: '0'
                                    })
                            }

                            if (subItem.labelType == '1') {
                                this.$set(subItem, 'value', '');
                            } else {
                                this.$set(subItem, 'value', []);
                            }
                                this.$set(subItem, 'otherDesc', '');
                                this.$set(subItem, 'otherInformation', '');
                                this.$set(subItem, 'isAbnormal', false);
                        })
                    }
                }

                if (item.id == '2' || item.id == '3' || item.id == '4') {
                    this.$set(item, 'otherInformation', '');
                }
            })

            this.reqObj.list.forEach((item, index) => {
                this.$set(item, '_list', this._.cloneDeep(this.evaluationForm))
            })

            this.evaluationFormAdd = this._.cloneDeep(this.evaluationForm)

            tourDetailsClone.list.forEach((item) => {
                this.reqObj.list.forEach((subItem) => {
                    if (item.id === subItem.id) {
                        console.log(item, subItem,'subItem-----')
                        if (!item.schoolClassObservedRecordEvaluateList || item.schoolClassObservedRecordEvaluateList.length == 0) {
                            this.loadingShow = false;
                            return
                        }

                        let teacherEvaluation = item.schoolClassObservedRecordEvaluateList.filter((it) => { return it.type == '1' });
                        let studentEvaluation = item.schoolClassObservedRecordEvaluateList.filter((it) => { return it.type == '2' });
                        console.log(teacherEvaluation, studentEvaluation, '---===')

                        subItem._list.forEach((pItem) => {
                            if (pItem.id == '0') {
                                console.log( pItem.labelList,'---- pItem.labelList')
                                pItem.labelList.forEach((it) => {
                                    teacherEvaluation.forEach((ct) => {
                                        if (it.labelId == ct.behaviorLabelId) {
                                            console.log(ct.otherInformation,'ct.otherInformationct.otherInformation')
                                            it['value'] = '';
                                            it['id'] = ct.id;
                                            it.otherDesc = ct.otherDesc;
                                            it.otherInformation = ct.otherInformation;
                                            it.isAbnormal = ct.isAbnormal == '1' ? true : false;

                                            if (it.labelType == '2') {
                                                console.log(it.labelName,it.labelType,it,'it',ct)
                                                if (typeof it.value == 'string') {

                                                    let val = ct.behaviorDataId.split(',');

                                                    it['value'] = [];
                                                    it.dataList.forEach((valIt) => {
                                                        val.forEach((valPt) => {
                                                            console.log(valIt.id,'valPt', valPt)
                                                            if (valIt.id == valPt) {
                                                                it['value'].push(valIt.dataName)
                                                            }
                                                        })
                                                    })
                                                } else {
                                                    it.value = []
                                                }
                                            } else {
                                                it.dataList.forEach((valIt) => {
                                                    if (valIt.id == ct.behaviorDataId) {
                                                        it['value'] = valIt.dataName;
                                                    }
                                                })

                                                console.log(it.labelName,it.labelType,it,'it',ct)
                                            }
                                        }
                                    })
                                })

                                console.log(pItem,'pItem数据')
                            }

                            if (pItem.id == '1') {
                                console.log(pItem.labelList,'-----pItem.labelList---')
                                if( pItem.labelList){
                                    pItem.labelList.forEach((it) => {
                                    studentEvaluation.forEach((ct) => {
                                        if (it.labelId == ct.behaviorLabelId) {

                                            it['value'] = '';
                                            it['id'] = ct.id;
                                            it.otherDesc = ct.otherDesc;
                                            it.otherInformation = ct.otherInformation;
                                            it.isAbnormal = ct.isAbnormal == '1' ? true : false;

                                            if (it.labelType == '2') {
                                                console.log(it.labelName,it.labelType,it,'it',ct)
                                                if (typeof it.value == 'string') {

                                                    let val = ct.behaviorDataId.split(',');

                                                    it['value'] = [];
                                                    it.dataList.forEach((valIt) => {
                                                        val.forEach((valPt) => {
                                                            console.log(valIt.id,'valPt', valPt)
                                                            if (valIt.id == valPt) {
                                                                it['value'].push(valIt.dataName)
                                                            }
                                                        })
                                                    })
                                                } else {
                                                    it.value = []
                                                }
                                            } else {
                                                it.dataList.forEach((valIt) => {
                                                    if (valIt.id == ct.behaviorDataId) {
                                                        it['value'] = valIt.dataName;
                                                    }
                                                })

                                                console.log(it.labelName,it.labelType,it,'it',ct)
                                            }
                                            console.log(it,'it',ct)
                                        }
                                    })
                                })
                                }

                            }

                            if (pItem.id == '2') {
                                pItem.otherInformation = item.highLights;
                            }

                            if (pItem.id == '3') {
                                pItem.otherInformation = item.question;
                            }

                            if (pItem.id == '4') {
                                pItem.otherInformation = item.instructionManual;
                            }
                        })
                    }
                })
            })

            // 拼装异常返回数据
            this.reqObj.list.forEach((item) => {
                tourDetailsClone.list.forEach((subItem) => {
                    console.log(item, subItem, '++++')
                    if (item.id === subItem.id) {
                        item._list.forEach((pIt) => {
                            if (pIt.labelList) {
                                subItem.schoolClassObservedRecordEvaluateList.forEach((i) => {
                                    pIt.labelList.forEach((k) => {
                                        if (k.id == i.id) {
                                            this.$set(k, 'abnormalResultFeedback', i.abnormalResultFeedback)
                                        }
                                    })
                                })
                            }
                        })
                    }
                })
            })


            console.log(this.reqObj, this.ruleForm,'this.reqObj++')

            this.reqObj.list.forEach((item) => {
                if (this.ruleForm.id == item.id) {
                    this.evaluationForm = this._.cloneDeep(item._list);
                }
            })
         },
         getAddForm (tourDetailsClone) {

            let teacherEvaluation = [],
                studentEvaluation = [];

            if (this.teacherStudentBehaviorLabelsTakeList.length > 0) {
                teacherEvaluation = this.teacherStudentBehaviorLabelsTakeList.filter((item) => { return item.identityType == '1' });
                studentEvaluation = this.teacherStudentBehaviorLabelsTakeList.filter((item) => { return item.identityType == '2' });
            }
            console.log(teacherEvaluation, studentEvaluation, '课程表')
            this.evaluationForm.forEach((item) => {
                if (item.id == '0') {
                    item['identityType'] = '1';
                    if (teacherEvaluation.length > 0) {
                        item['labelList'] = teacherEvaluation[0].labelList;
                        item['labelList'].forEach((subItem) => {
                            if (subItem.dataList) {
                                subItem.dataList.push({
                                    dataName: '其他',
                                    id: '0'
                                })
                            } else {
                                subItem['dataList'] = [];
                                subItem['dataList'].push({
                                    dataName: '其他',
                                    id: '0'
                                })
                            }

                            if (subItem.labelType == '1') {
                                this.$set(subItem, 'value', '');

                                subItem.dataList.forEach((it) => {
                                    if (!subItem.value && it.isDefault == '1') {
                                        subItem.value = it.dataName
                                    }
                                })
                            } else {
                                this.$set(subItem, 'value', []);

                                let fil = subItem.dataList.filter((it) => {
                                    return it.isDefault == '1';
                                })

                                if (subItem.value.length == 0 && fil.length > 0) {
                                    fil.forEach((it) => {
                                        subItem.value.push(it.dataName)
                                    })
                                }
                            }

                            this.$set(subItem, 'otherDesc', '');
                            this.$set(subItem, 'otherInformation', '');
                            this.$set(subItem, 'isAbnormal', false);

                        })
                    }

                }

                if (item.id == '1') {
                    item['identityType'] = '2';
                    if (studentEvaluation.length > 0) {
                        item['labelList'] = studentEvaluation[0].labelList;
                        item['labelList'].forEach((subItem) => {
                            if (subItem.dataList) {
                                subItem.dataList.push({
                                    dataName: '其他',
                                    id: '0'
                                })
                            } else {
                                subItem['dataList'] = [];
                                subItem['dataList'].push({
                                    dataName: '其他',
                                    id: '0'
                                })
                            }

                            if (subItem.labelType == '1') {
                                this.$set(subItem, 'value', '');

                                subItem.dataList.forEach((it) => {
                                    if (!subItem.value && it.isDefault == '1') {
                                        subItem.value = it.dataName
                                    }
                                })
                            } else {
                                this.$set(subItem, 'value', []);

                                let fil = subItem.dataList.filter((it) => {
                                    return it.isDefault == '1';
                                })

                                if (subItem.value.length == 0 && fil.length > 0) {
                                    fil.forEach((it) => {
                                        subItem.value.push(it.dataName)
                                    })
                                }
                            }

                            this.$set(subItem, 'otherDesc', '');
                            this.$set(subItem, 'otherInformation', '');
                            this.$set(subItem, 'isAbnormal', false);
                        })
                    }

                }

                if (item.id == '2' || item.id == '3' || item.id == '4') {
                    this.$set(item, 'otherInformation', '');
                }
            })

            this.reqObj.list.forEach((item, index) => {
                this.$set(item, '_list', this._.cloneDeep(this.evaluationForm))
                item.isResultSend = false;
                item.isExcellentExcellent = false;
            })

            this.evaluationFormAdd = this._.cloneDeep(this.evaluationForm)
         },
        /**
         * @Description: 获取接口数据
         * @DoWhat: 获取接口数据
         * @UseScenarios: 获取接口数据
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-18 11:15:40
         */
         async initPageData () {
            await this.getAcquisitionDepartment() // 根据老师获取部门

            await this.getTourYear(); // 获取年级
            await this.getClasses(); // 获取班级
            // await this.getCourse(); // 获取课程
            await this.getClassTimeSlots(); // 获取上课时间段
            await this.getSupervisingTeachers()// 获取督导教师

            await this.getTeacherStudentBehaviorLabelsTake() // 获取教师学生行为标签

            await this.getCourseInfo(); // 根据班级、上课日期查询课程信息

            await this.assemblyData(this.tourDetailsClone)
         },
         async getAcquisitionDepartment () {
            const selectT = this.tourDetailsClone.selectT;
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            this.tabList = [];

            for (var i = 0; i < selectT.length; i++) {
                await teachingResearchCourseScheduleTempModel.getTeaOrganByTeacherId({'schoolId': this.schoolId, 'teacherId': selectT[i].id }).then((res) => {
                    if (res.data.code == '200') {
                        let obj = {
                            id: res.data.data[0].id,
                            title: res.data.data[0].organName + ' ' +selectT[i].teacherName,
                            teacherId: selectT[i].id,
                            _id: i
                        }
                        console.log(obj,'obj+++')
                        this.tabList.push(obj)
                    } else if (res.data.msg){
                        this.$message.error(res.data.msg)
                    }
                })
            }

            console.log(this.tabList,'this.tabList')

            if (this.tourDetailsClone.type != 'add') {
                this.selectTabId = 0;
            } else {
                let obj = {
                    id: '0',
                    title: '卡片1',
                    teacherId: '',
                    _id: '0'
                }
                this.tabList.push(obj);
                this.selectTabId = 0;

            }

         },
         async getTourYear () {
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            await teachingResearchCourseScheduleTempModel.getGradeList({'schoolId': this.schoolId}).then((res) => {
                if (res.data.code == '200') {
                    console.log(res.data.data,'res.data.data年级数据')
                    this.gradeList = res.data.data;
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })
         },
         async getClasses () {
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            await teachingResearchCourseScheduleTempModel.getClassByGradeId({'schoolId': this.schoolId, 'gradeId': this.ruleForm.gradeId }).then((res) => {
                if (res.data.code == '200' ) {
                    console.log('根据年级获取班级',this.tourDetailsClone.type, res.data.data)
                    this.classList = res.data.data;
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })
         },
        //  async getCourse () {
        //     const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
        //     await teachingResearchCourseScheduleTempModel.getSchoolSubjectsList({'schoolId': this.schoolId, 'dictKey':"teachingSubjects"}).then((res) => {
        //         if (res.data.code == '200') {
        //             this.courseList = res.data.data
        //         } else if (res.data.msg){
        //             this.$message.error(res.data.msg)
        //         }
        //     })
        //  },
         async getClassTimeSlots () {
            this.conditionList.gradeId = this.ruleForm.classId;
            this.conditionList.isArrange = '1';
            this.classPeriodList = [];
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            let res = await teachingResearchCourseScheduleTempModel.getClassWeek(this.conditionList)
            if (res.data.code == '200') {
                res.data.data.forEach((item) => {
                    let obj = {
                        id: item.id,
                        label: `${item.name} ${item.startTime}-${item.endTime}`,
                        value: item.id
                    };
                    this.classPeriodList.push(obj)
                })
                console.log(this.classPeriodList, '上课时间段----')
            } else if (res.data.msg) {
                this.$message.error(res.data.msg)
            }
         },
         async getSupervisingTeachers () {
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();

            await teachingResearchCourseScheduleTempModel.getSchoolTeacherList({'schoolId': this.schoolId, "applyPerson": "1"}).then((res) => {
                if (res.data.code == '200') {
                    console.log(res.data.data,'res.data.data 督导教师数据')
                    this.setDisabledOption(res.data.data)
                    this.supervisingTeachersList = res.data.data;
                    this.supervisingTeachersOriginList = res.data.data;
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })
         },
         async getTeacherStudentBehaviorLabelsTake () {
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            await teachingResearchCourseScheduleTempModel.teacherStudentBehaviorLabelsTake(this.labelsTake).then((res) => {
                if (res.data.code == '200') {
                    console.log(res.data, 'getTeacherStudentBehaviorLabelsTake')
                    this.teacherStudentBehaviorLabelsTakeList = res.data.data;
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })
         },
         async getCourseInfo () {
            console.log(this.tourDetails, this.ruleForm, 'this.tourDetails', 'ruleForm')
            let reqObj = {
                scheduleClassId: this.ruleForm.classId || this.tourDetails.scheduleClassId,
                scheduleTime: this.ruleForm.classTime || this.tourDetails.scheduleTime,
                schoolId: this.schoolId,
                productId: 'cloud_campus'
            }
            if (reqObj.scheduleClassId && reqObj.scheduleTime) {
                const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
                    await teachingResearchCourseScheduleTempModel.getCourseInfo(reqObj).then((res) => {
                    if (res.data.code == '200' && res.data.data.length > 0) {
                        // this.supervisingTeachersList1 = [];
                        this.courseList = res.data.data;
                        console.log(this.courseList,'this.courseList 课程')
                    }
                })
            }
         },
         setDisabledOption (list) {
            list.forEach((item) => {
                if (item.key !== 'teacher') {
                    this.$set(item, 'disabled', true)
                } else {
                    this.$set(item, 'disabled', false)
                }

                if (item.children && item.children.length > 0) {
                    this.setDisabledOption (item.children)
                }
            })
         },
        /**
         * @Description:
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2022-11-25 20:58:01
         */
         getSupervisingTeachersOptionsList (list, tList) {
            list.forEach((item) => {
                tList.forEach((subItem) => {
                    if (item.id == subItem) {
                        this.supervisingTeachersList1.push(item);
                        this.supervisingTeachersList.push(item);
                    }
                })

                if (item.children && item.children.length > 0) {
                    this.getSupervisingTeachersOptionsList (item.children, tList)
                }

            })
         },
        /**
         * @Description: 添加tabs某一项
         * @DoWhat: 添加tab卡片
         * @UseScenarios: 添加tab卡片
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-09 10:16:45
         */
         addTabCards: debounce (function () {
            console.log(this.tabList)
            let idx = Number(this.tabList[this.tabList.length - 1]._id);
            let obj = {
                id: idx + 1 + '',
                teacherId: '',
                title: '卡片' + (idx + 2),
                _id: idx + 1
            }
            console.log(obj,'obj--obj')
            this.tabList.push(obj);

            let reqObjItem = this._.cloneDeep(this.reqObjItem)
            reqObjItem['_list'] = this._.cloneDeep(this.evaluationFormAdd)
            reqObjItem['productId'] = 'cloud_campus';
            reqObjItem['schoolId'] = this.schoolId;
            this.reqObj.list.push(this._.cloneDeep(reqObjItem))
            this.reqObj.list[this.reqObj.list.length - 1].isResultSend = false;
            this.reqObj.list[this.reqObj.list.length - 1].isExcellentExcellent = false;


            // 清除下拉数据
            this.courseList = [];
            this.supervisingTeachersList1 = [];
            // 如果是添加或者巡课清楚下拉
            if (this.tourDetailsClone.type == 'continueTour' || this.tourDetailsClone.type == 'add') {
                this.supervisingTeachersList = [];
            }


            console.log(this.reqObj.list, this.tabList,'this.reqObj.list')
         }, 200),
        /**
         * @Description: tab切换
         * @DoWhat: tab切换
         * @UseScenarios: tab切换
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-19 16:42:34
         */
        handlerTabChange: debounce (function (data) {
            // 清除校验
            // this.$refs['ClassPatrolForm'].resetFields();
            this.selectTabId = Number(data._id);
            this.formTabsPointer = '5'

            let idx = this.getFormListIdx();



            Object.keys(this.reqObj.list[idx]).forEach((key) => {
                Object.keys(this.ruleForm).forEach((subKey) => {
                    if (key == subKey) {
                        this.ruleForm[subKey] = JSON.parse(JSON.stringify(this.reqObj.list[idx][key]));
                    }
                })
            })



            if (this.optionsAllList[idx]) {
                this.supervisingTeachersList1 = this.optionsAllList[idx]['supervisingTeachersList1'];
                this.classPeriodList = this.optionsAllList[idx]['classPeriodList'];
                this.courseList = this.optionsAllList[idx]['courseList'];

                if (this.tourDetailsClone.type == 'continueTour' || this.tourDetailsClone.type == 'add') {
                    this.supervisingTeachersList = this.optionsAllList[idx]['supervisingTeachersList'];
                }
            }




            this.evaluationForm = this.reqObj.list[idx]._list;
            this.formTabsPointer = '0';

            console.log(this.supervisingTeachersList,this.optionsAllList[idx],'this.ruleForm[subKey]+++')


        }, 250),
        /**
         * @Description: 全部提交
         * @DoWhat: 全部提交
         * @UseScenarios: 全部提交
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-20 22:00:37
         */
         submitAll () {
            console.log(this.reqObj, '全部提交')
            if (this.checkState() && this.checkEvaluationState()) {
                //this.reqObj.continueTourId = this.tourDetailsClone.id;
                this.sendData('whole')
            } else {
                this.$message.error('请填写完整信息')
            }
         },
         /**
          * @Description: 删除卡片
          * @DoWhat:
          * @UseScenarios:
          * @Attention:
          * @Author: 武东超
          * @Date: 2023-05-17 16:45:54
          */
          delCard (data) {
            const { item, index } = data;
            this.tabList.map((subItem, subIndex) => {
                if (subItem.id === item.id) {
                    this.tabList.splice(subIndex, 1)
                }
            })
            this.handlerTabChange(this.tabList[0])
            console.log(item, index,'item, index')
          },
         checkState () {
            let state = true;
            console.log(this.reqObj.list,'this.reqObj.list')
            this.reqObj.list.forEach((item) => {
                if (!item.classId) {
                    state = false;
                }
                if (!item.courseId) {
                    state = false;
                }
                if (!item.deptId) {
                    state = false;
                }
                if (!item.gradeId) {
                    state = false;
                }
                if (!item.restTimeId) {
                    state = false;
                }
                if (!item.supervisingTeacherId) {
                    state = false;
                }
            })

            return state;
         },
         /**
          * @Description: 校验评价
          * @DoWhat: 校验评价
          * @UseScenarios: 校验评价
          * @Attention: 校验评价
          * @Author: 武东超
          * @Date: 2022-11-21 08:23:29
          */
         checkEvaluationState () {
            let state = true;
            this.reqObj.list.forEach((item) => {
                item._list.forEach((subItem) => {
                    console.log(subItem,'subItem 实时保存数据')
                    if (subItem.id == '0') {
                        if (!subItem.labelList || subItem.labelList.length == 0) {
                            //this.$message.warning('请先在巡课设置中维护教师、学生行为标签及行为数据')
                            return;
                        };
                        subItem.labelList.forEach((pItem) => {
                            // 校验是否有选中项
                            if (typeof pItem.value == 'string') {
                                if (!pItem.value) {
                                    console.log('0-----1')
                                    state = false;
                                }
                                if (pItem.value == '其他' && !pItem.otherDesc) {
                                    console.log('0-----5')
                                    state = false;
                                }
                            } else if (pItem.value.length == 0) {
                                console.log('0-----2')
                                state = false;
                            }
                            // 校验选中项目中有其他选项，必须填写输入框
                            if (typeof pItem.value == 'object' && pItem.value.length > 0) {
                                let idx = pItem.value.indexOf('其他');

                                if (idx > -1 && !pItem.otherDesc) {
                                    console.log('0-----3')
                                    state = false;
                                }
                            }

                            if (typeof pItem.value == 'string' && pItem.value) {
                                if (pItem.value == '其他' && !pItem.otherDesc) {
                                    console.log('0-----4')
                                    state = false;
                                }
                            }

                        })
                    }
                    if (subItem.id == '1') {
                        if (!subItem.labelList || subItem.labelList.length == 0) {
                            //this.$message.warning('请先在巡课设置中维护教师、学生行为标签及行为数据')
                            return
                        };
                        subItem.labelList.forEach((pItem) => {
                            // 校验是否有选中项
                            if (typeof pItem.value == 'string') {
                                if (!pItem.value) {
                                    console.log('1-----1')
                                    state = false;
                                }
                                if (pItem.value == '其他' && !pItem.otherDesc) {
                                    console.log('1-----5')
                                    state = false;
                                }
                            } else if (pItem.value.length == 0) {
                                console.log('2-----2')
                                state = false;
                            }
                            // 校验选中项目中有其他选项，必须填写输入框
                            if (typeof pItem.value == 'object' && pItem.value.length > 0) {
                                let idx = pItem.value.indexOf('其他');
                                console.log(idx,'测试多选')
                                if (idx > -1 && !pItem.otherDesc) {
                                    console.log('3-----3')
                                    state = false;
                                }

                            }

                            if (typeof pItem.value == 'string' && pItem.value) {
                                if (pItem.value == '其他' && !pItem.otherDesc) {
                                    console.log('1-----4')
                                    state = false;
                                }
                            }
                        })
                    }
                })
            })

            return state;
         },
        /**
         * @Description: 点击表单切换tabs
         * @DoWhat: 点击表单切换tabs
         * @UseScenarios: 点击表单切换tabs
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-09 15:58:27
         */
         changeFormTabs (data) {
            this.formTabsPointer = data.id;
            console.log(data,'data')
         },
        /**
         * @Description: 更改年级修改清楚班级
         * @DoWhat: 更改年级修改清楚班级
         * @UseScenarios: 更改年级修改清楚班级
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-19 08:47:14
         */
         handlerTourGrade () {

            let idx = this.getFormListIdx()
            console.log(idx,'idx')
            this.ruleForm.classId = '';
            this.ruleForm.courseId = '';
            this.reqObj.list[idx].classId = '';
            this.reqObj.list[idx].courseId = '';

            // 清除课程
            this.ruleForm.courseId = '';
            this.reqObj.list[idx].courseId = '';
            this.courseList = [];
            // 清除上课时间段
            this.ruleForm.restTimeId = '';
            this.reqObj.list[idx].restTimeId = '';
            //this.classPeriodList = [];
            // 清除督导教师
            this.ruleForm.supervisingTeacherId = '';
            this.reqObj.list[idx].supervisingTeacherId = '';
            this.supervisingTeachersList1 = [];

            if (this.tourDetailsClone.type == 'continueTour' || this.tourDetailsClone.type == 'add') {
                this.supervisingTeachersList = this.optionsAllList[idx]['supervisingTeachersList'];
            }

            this.getClasses();
            console.log(this.reqObj,'this.reqObj')
         },
         /**
          * @Description: 课程改变
          * @DoWhat: 课程改变
          * @UseScenarios: 课程改变
          * @Attention: 无
          * @Author: 武东超
          * @Date: 2022-11-25 17:42:22
          */
         handlerCourseIdChange () {
            let idx = this.getFormListIdx()
            this.ruleForm.restTimeId = '';
            this.reqObj.list[idx].restTimeId = '';

            this.ruleForm.supervisingTeacherId = '';
            this.reqObj.list[idx].supervisingTeacherId = '';

            console.log(this.courseList,'courseList')
            this.courseList.forEach((item) => {
                if (this.ruleForm.courseId == item.id) {
                    this.ruleForm.restTimeId = item.restTimeId;
                    this.reqObj.list[idx].restTimeId = item.restTimeId;
                }
            })
            // 督导教师
            this.courseList.forEach((item) => {
                if (this.ruleForm.courseId == item.id) {
                    let tList = item.supervisingTeacherId.split(',');

                    // 获取督导教师下拉
                    this.supervisingTeachersList1 = [];
                    if (this.tourDetailsClone.type == 'continueTour' || this.tourDetailsClone.type == 'add') {
                        this.supervisingTeachersList = [];
                    }
                    this.getSupervisingTeachersOptionsList(this.supervisingTeachersOriginList, tList)

                    this.supervisingTeachersList = this.deduplication(this.supervisingTeachersList)
                    this.supervisingTeachersList1 = this.deduplication(this.supervisingTeachersList1)
                }
            })
         },
         deduplication (arr) {
            let map = new Map();
            for (let item of arr) {
                if (!map.has(item.id)) {
                    map.set(item.id, item);
                }
            }
            return [...map.values()];
         },
        /**
         * @Description: 上课时间段修改
         * @DoWhat: 上课时间段修改
         * @UseScenarios: 上课时间段修改
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-25 17:51:30
         */
         handlerRestTimeIdChange () {
            let idx = this.getFormListIdx()
            console.log(this.ruleForm,  this.reqObj.list[idx], '==')
            this.ruleForm.supervisingTeacherId = '';
            this.reqObj.list[idx].supervisingTeacherId = '';
         },
        /**
         * @Description: 班级修改
         * @DoWhat: 班级修改
         * @UseScenarios: 班级修改
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-25 17:56:31
         */
         handlerClassIdChange () {
            let idx = this.getFormListIdx()
            console.log(this.ruleForm,  this.reqObj.list[idx], '==')
            this.ruleForm.supervisingTeacherId = '';
            this.reqObj.list[idx].supervisingTeacherId = '';
            this.supervisingTeachersList1 = [];
            // 课程
            this.ruleForm.courseId = '';
            this.reqObj.list[idx].courseId = '';
            this.courseList = [];
            // 上课时间段
            this.ruleForm.restTimeId = '';
            this.reqObj.list[idx].restTimeId = '';
            //this.classPeriodList = [];

            //this.getClassTimeSlots ()
            this.getCourseInfo();
         },
        /**
         * @Description: 上课日期清除
         * @DoWhat: 上课日期清除
         * @UseScenarios: 上课日期清除
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-26 18:32:40
         */
         handlerClassTimeChange () {
            let idx = this.getFormListIdx()
            // 课程
            this.ruleForm.courseId = '';
            this.reqObj.list[idx].courseId = '';
            this.courseList = [];
            // 上课时间段
            this.ruleForm.restTimeId = '';
            this.reqObj.list[idx].restTimeId = '';
            // 督导教师
            this.ruleForm.supervisingTeacherId = '';
            this.reqObj.list[idx].supervisingTeacherId = '';
            this.supervisingTeachersList1 = [];
         },
        /**
         * @Description: 获取tab选中项
         * @DoWhat: 获取tab选中项
         * @UseScenarios: 获取tab选中项
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-19 10:21:08
         */
        getFormListIdx () {
            let idx = '';
            this.tabList.forEach((item, index) => {
                if (this.selectTabId == item._id) {
                    idx = item._id;
                }
            })
            return idx;
        },
        /**
         * @Description: 获取卡片名称
         * @DoWhat: 获取卡片名称
         * @UseScenarios: 获取卡片名称
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-21 00:44:40
         */
         handlerSupervisingTeacherChange () {
            if (this.ruleForm.supervisingTeacherId && (this.tourDetailsClone.type == 'add' || this.tourDetailsClone.type == 'continueTour')) {
                let obj = {
                    id: '',
                    title: '',
                    teacherId: ''
                },
                idx;

                const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
                teachingResearchCourseScheduleTempModel.getTeaOrganByTeacherId({'schoolId': this.schoolId, 'teacherId': this.ruleForm.supervisingTeacherId }).then((res) => {
                    if (res.data.code == '200') {
                        console.log(res.data.data[0].organName,'res')
                        console.log( this.supervisingTeachersList, '督导教师数据选择了')
                        this.getSupervisingTeachersName(this.supervisingTeachersList, this.ruleForm.supervisingTeacherId, obj, idx, res.data.data[0])
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg)
                    }
                })
            }
         },
        /**
         * @Description: 获取督导教师name
         * @DoWhat: 获取督导教师name
         * @UseScenarios: 获取督导教师name
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-25 16:23:56
         */
         getSupervisingTeachersName (supervisingTeachersList, supervisingTeacherId, obj, idx, data) {
            supervisingTeachersList.forEach((item) => {
                if (supervisingTeacherId == item.id) {
                    obj.id = data.organId;
                    obj.title = data.organName + ' ' + item.name;
                    obj.teacherId = this.ruleForm.supervisingTeacherId;

                    this.tabList.forEach((item, index) => {
                        if (this.selectTabId == item._id) {
                            idx = item._id;
                        }
                    })


                    console.log(data,'datadatadatadatadata')
                        // obj.id = data.organId;
                        // obj.title = data.organName + ' ' + item.name;
                        // obj.teacherId = this.ruleForm.supervisingTeacherId;

                        this.tabList.splice(idx, 1, Object.assign(this.tabList[idx],obj))

                        // this.tabList.forEach((item, index) => {
                        //     if (item.id == obj.id) {
                        //         this.selectTabId = item._id;
                        //     }
                        // })

                        console.log(this.selectTabId,idx,'this.selectTabId')
                        this.ruleForm.deptId = data.organId;
                        this.reqObj.list[idx].deptId = data.organId;
                        this.reqObj.list[idx].watchTitle = obj.title;


                    if (this.ruleForm.supervisingTeacherId == item.id) {

                    }
                }

                if (item.children && item.children.length > 0) {
                    this.getSupervisingTeachersName (item.children, supervisingTeacherId, obj, idx, data)
                }

            })
         },
        /**
         * @Description: 课程基本信息/设置项_深度侦听评价数据
         * @DoWhat: 课程基本信息/设置项_深度侦听评价数据
         * @UseScenarios: 课程基本信息/设置项_深度侦听评价数据
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-19 15:32:35
         */
         handlerRuleFormChangeDeep: debounce (function () {
            let idx = this.getFormListIdx(),
                cloneRuleForm = this._.cloneDeep(this.ruleForm);

            Object.keys(cloneRuleForm).forEach((key) => {
                Object.keys(this.reqObj.list[idx]).forEach((subKey) => {
                    if (key == subKey && subKey !== 'watchTitle' && subKey !== 'schoolId' && subKey !== 'productId') {
                        this.reqObj.list[idx][subKey] = cloneRuleForm[key];
                    }
                })
            })
            console.log(this.reqObj.list[idx],idx,'this.reqObj.list[idx]')
            this.getCourseInfo ()
         }, 200),
        /**
         * @Description: 评价_深度侦听评价数据
         * @DoWhat: 评价_深度侦听评价数据
         * @UseScenarios: 评价_深度侦听评价数据
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-19 14:55:17
         */

        handlerChangeDeep: debounce (function() {
            let idx = this.getFormListIdx();
            if(idx){
                this.$set(this.reqObj.list[idx], '_list', this._.cloneDeep(this.evaluationForm))
            }
            console.log(this.reqObj,'console.log(this.reqObj)')
            // console.log('this.reqObj.list[idx]--',idx)

            // console.log(idx, this.reqObj.list[idx]._list,this.evaluationForm,'11111this.reqObj')
        }, 100),
        /**
         * @Description: 完整保存/实时 发送数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2022-11-19 18:19:48
         */
        sendData: debounce (function (type) {
            if (!this.checkState()) return;
            if (this.tourDetailsClone.type == 'detail') return;
            let sedD = this._.cloneDeep(this.reqObj);
            switch (type) {
                case 'realTime':
                sedD.type = '1'
                    break;
                case 'whole':
                sedD.type = '2'
                break;
                default:
                    break;
            }

            switch (this.tourDetailsClone.type) {
                case 'edit':
                sedD.type = '2'
                    break;
                case 'detail':
                sedD.type = '2'
                    break;
                    default:
                    break;
            }



            console.log(sedD,'sedD')

            sedD.list.forEach((item) => {
                let allList = [];
                let list_filter = item._list.filter((subItem) => { return  subItem.id == '0' || subItem.id == '1'})
                console.log(list_filter,'list_filter')

                list_filter.forEach((subItem) => {
                    console.log(subItem,'subItem-')
                    if (!subItem.labelList || subItem.labelList.length == 0) {
                        //this.$message.warning('请先在巡课设置中维护教师、学生行为标签及行为数据')
                        return
                    };
                    subItem.labelList.forEach((pItem) => {
                        console.log(pItem.value, 'subItem.value-')
                        let val = [];
                        if (typeof pItem.value == 'string') {
                            pItem.dataList.forEach((it) => {
                                if (it.dataName == pItem.value) {
                                    val.push(it.id)
                                }
                            })
                        } else {
                            pItem.dataList.forEach((it) => {
                                pItem.value.forEach((cIt) => {
                                    if (it.dataName == cIt) {
                                        val.push(it.id)
                                    }
                                })
                            })
                        }

                        if (!val.includes('0')) {
                            pItem.otherDesc = '';
                        }

                        let obj = {
                            type: subItem.identityType,
                            labelName: pItem.labelName,
                            behaviorLabelId: pItem.labelId,
                            behaviorDataId: val.toString(),
                            behaviorDataName: pItem.value.toString(),
                            otherDesc: pItem.otherDesc,
                            otherInformation: encodeURIComponent(pItem.otherInformation),
                            isAbnormal: pItem.isAbnormal == true ? '1' : '0',
                            abnormalResultFeedback: ''
                        }
                        allList.push(obj)
                    })
                })
                item['list'] = allList;
                item['highLights'] = item._list.filter((subItem) => { return  subItem.id == '2' })[0].otherInformation ? encodeURIComponent(item._list.filter((subItem) => { return  subItem.id == '2' })[0].otherInformation) : '';
                item['question'] = item._list.filter((subItem) => { return  subItem.id == '3' })[0].otherInformation ? encodeURIComponent(item._list.filter((subItem) => { return  subItem.id == '3' })[0].otherInformation) : '';
                item['instructionManual'] = item._list.filter((subItem) => { return  subItem.id == '4' })[0].otherInformation ? encodeURIComponent(item._list.filter((subItem) => { return  subItem.id == '4' })[0].otherInformation) : '';
                // console.log(item,allList,'allList--')
            })

            // 删除辅助数据
            sedD.list.forEach((item) => {
                delete item._list;
            })


            console.log(sedD,'sedD')

            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            teachingResearchCourseScheduleTempModel.schoolClassRecordSave(sedD).then((res) => {
                if (res.data.code == '200') {
                    res.data.data.forEach((item, index) => {
                        this.reqObj.list.forEach((subItem, subIndex) => {
                            if (index === subIndex) {
                                subItem['id'] = item.id;
                            }
                        })
                    })

                    let idx = this.getFormListIdx();
                    console.log(idx,'idx')
                    this.ruleForm.id = this.reqObj.list[idx].id;

                    if (type == 'whole') {
						this.$message.success('提交成功')
                        this.$emit('classPatrolBack')
                    }

                } else if (res.data.code == '100305') {
                    this.$message.error(res.data.msg)
                    // this.$emit('classPatrolBack')
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })

        }, 300)
    },
    watch: {
        'reqObj': {
            handler () {
                this.sendData('realTime')
            },
            deep: true
        },
        'ruleForm': {
            handler () {
                this.handlerRuleFormChangeDeep()
                console.log(this.ruleForm,'ruleForm')
            },
            deep: true
        },
        'supervisingTeachersList1': {
            handler () {
                // 督导教师 改变存起来
                let idx = this.getFormListIdx(),
                    obj = {
                        supervisingTeachersList: [],
                        supervisingTeachersList1: [],
                        classPeriodList: [],
                        courseList: []
                    };

                if (this.optionsAllList[idx]) {
                    this.optionsAllList[idx]['supervisingTeachersList1'] = this.supervisingTeachersList1;
                } else {
                    this.optionsAllList.splice(idx, 1 , obj);
                    this.optionsAllList[idx]['supervisingTeachersList1'] = this.supervisingTeachersList1;
                }

                console.log(this.supervisingTeachersList1, this.optionsAllList, '督导教师 选项卡')
            },
            deep: true
        },
        'classPeriodList': {
            handler () {
                // 上课时间段 改变存起来
                let idx = this.getFormListIdx(),
                    obj = {
                        supervisingTeachersList: [],
                        supervisingTeachersList1: [],
                        classPeriodList: [],
                        courseList: []
                    },
                    _this = this;
                 if (!idx) return
                    console.log(idx,'idx')
                    console.log(_this.classPeriodList,'this.classPeriodList=========')
                    if (this.optionsAllList[idx]) {
                    this.optionsAllList[idx]['classPeriodList'] = _this.classPeriodList;
                } else {
                    this.optionsAllList.splice(idx, 1 , obj);
                    let ar1 = _this.classPeriodList.length > 0 ? _this.classPeriodList : [];
                    this.optionsAllList[idx]['classPeriodList'] = ar1;
                }
                console.log(this.classPeriodList, this.optionsAllList, '上课时间段 选项卡')
            },
            deep: true
        },
        'courseList': {
            handler () {
                // 上课时间段 改变存起来
                let idx = this.getFormListIdx(),
                    obj = {
                        supervisingTeachersList: [],
                        supervisingTeachersList1: [],
                        classPeriodList: [],
                        courseList: []
                    };

                if (this.optionsAllList[idx]) {
                    this.optionsAllList[idx]['courseList'] = this.courseList;
                } else {
                    this.optionsAllList.splice(idx, 1 , obj);
                    this.optionsAllList[idx]['courseList'] = this.courseList;
                }

                console.log(this.courseList, this.optionsAllList, '课程 选项卡')
            },
            deep: true
        },
        'supervisingTeachersList': {
            handler () {
                // 上课时间段 改变存起来
                let idx = this.getFormListIdx(),
                    obj = {
                        supervisingTeachersList: [],
                        supervisingTeachersList1: [],
                        classPeriodList: [],
                        courseList: []
                    };
                    if (!idx) return;
                    if (this.optionsAllList[idx]) {
                    this.optionsAllList[idx]['supervisingTeachersList'] = this.supervisingTeachersList;
                } else {
                    this.optionsAllList.splice(idx, 1 , obj);
                    this.optionsAllList[idx]['supervisingTeachersList'] = this.supervisingTeachersList;
                }

                console.log(this.supervisingTeachersList, this.optionsAllList, '督导教师全部 选项卡')
            },
            deep: true
        },
        'ruleForm.supervisingTeacherId': {
            handler () {
                let idx = this.getFormListIdx();
                let num = Number(idx) + 1;

                if (this.reqObj.list[idx].watchTitle && !this.ruleForm.supervisingTeacherId) {
                    this.tabList[idx].title = '卡片' + num
                    this.reqObj.list[idx].watchTitle = this.tabList[idx].title;

                    console.log(this.tabList,'tabList')
                }
            },
            deep: true
        }
    }
}
</script>
<style lang="scss" scoped>
    .class-patrol-temp-wrapper {
        box-sizing: border-box;
        padding: 10px;
        padding-top: 0;
        padding-left: 0;
        .content-wrapper {
            background-color: #fff;
            box-sizing: border-box;
            padding: 16px 20px;
        }
    }
</style>
